// Step3.js

import React, { useState } from 'react';
import '../global.css';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiOpenInNew } from '@mdi/js';


function Step3({onSubmit, recomendations}) {
  
  // State to track visibility of each card content
    const [visibleCards, setVisibleCards] = useState(
      Object.fromEntries(recomendations.map(([causeOfAction]) => [causeOfAction, false]))
    );
  
    // Toggle visibility function
    const toggleVisibility = (causeOfAction) => {
      setVisibleCards((prevVisibleCards) => ({
        ...prevVisibleCards,
        [causeOfAction]: !prevVisibleCards[causeOfAction] // Toggle the visibility
      }));
    };

  return (
    <div>      
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Recommendations</h1>
      </div>

      <form onSubmit={onSubmit}>
        {/* <div className='block'>
        <textarea className="textarea" name="recomendations" id="recomendations" defaultValue={recomendations.body} rows="20" cols="60"></textarea>
        </div> */}

        <div>
          {recomendations.map(([causeOfAction, recArray]) => (
            <div key={causeOfAction} className='card box is-draft' style={{ padding: '0' }}>
              <header className={`card-header ${visibleCards[causeOfAction] ? 'is-expanded' : ''}`} style={{ boxShadow: 'none' }}>
                <h2 className="subtitle title is-7" style={{ margin: '0px 0px 0px 16px', flex: 1 }}>{causeOfAction}</h2>
                <button type="button" className="card-header-icon" aria-label="toggle visibility" onClick={() => toggleVisibility(causeOfAction)}>
                  <Icon path={visibleCards[causeOfAction] ? mdiChevronUp : mdiChevronDown} size={1} />
                </button>
              </header>
              {visibleCards[causeOfAction] && (
                <div className="card-content" style={{ padding: '20px 24px 12px 24px' }}>
                  <ul>
                    {recArray.map((recObj, index) => (
                      <li key={index} style={{ marginBottom: '0.5rem' }}>
                        <strong>Recommendation {index + 1}:</strong> {recObj.recommendation}

                        {recObj.citations_content && recObj.citations_content.length > 0 && (
                          <ul style={{ paddingTop: "4px" }}>
                            {recObj.citations_content.map((citation_content, cIndex) => (
                              <li key={cIndex}>
                                <strong>Citation:</strong> {citation_content}
                              </li>
                            ))}
                          </ul>
                        )}
                        
                        {/* If you'd like to show citations: */}
                        <div className="citation-link">
                          <div style={{ marginBottom: "4px"}}>
                            <p className='p bold' style={{ color: "#697586" }}>View citations in Case Viewer</p>
                          </div>
                          {recObj.citations && recObj.citations.length > 0 && (
                            <ul>
                              {recObj.citations.map((citation, cIndex) => (
                                <li key={index}>
                                  {/* <a href={`${citation.prefix}/${citation.id}`} target="_blank" rel="noopener noreferrer">
                                    {citation.displayName}
                                  </a> */}
                                    <a
                                      href={citation.type === 'state'
                                        ? citation.prefix
                                        : `${citation.prefix}/${citation.citationName}${citation.id ? `&${citation.id}` : ''}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ display: 'inline-flex', alignItems: 'center' }}
                                    >
                                      {citation.displayName}
                                      <Icon 
                                        path={mdiOpenInNew}
                                        size={0.7}
                                        style={{ marginLeft: '6px' }}
                                        aria-label="Open in new window"
                                      />
                                    </a>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="has-text-right" style={{ marginTop: "24px" }}>
          <button className="button is-normal is-success" type="submit">Proceed to download</button>
        </div>
      </form>
    </div>
  );
}

export default Step3;
