
// location of the backend
// export const API_URL = 'http://127.0.0.1:5000/api/';
export const API_URL = 'https://testapi.lextext.ai/api/';
// export const API_URL = 'https://api.lextext.ai/api/';

// AWS Cognito settings, test and local
export const userPoolId = "us-east-1_x8GRP09XU";
export const userPoolClientId = "7lq6c66o0l77e7fest715caps8";
export const identityPoolId = "us-east-1:08434519-1894-4d7b-8701-86b46274ff96";

// AWS Cognito settings, production
// export const userPoolId = "us-east-1_EUWrFCow8";
// export const userPoolClientId = "5cgjaf4p967ultmok8oebjst9b";
// export const identityPoolId = "us-east-1:595d5b44-fd43-491c-b5f9-2d02ba0706bf";